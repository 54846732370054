import { AuthenticationContext } from "@moneta/core/authentication";
import { Pathname, URLSegment } from "@moneta/core/entities";
import { GetFrontendInstrumentationConfiguration } from "@moneta/core/instrumentation";
import { DashboardUrlBuilder } from "@moneta/core/services";
import { FindUserById } from "@moneta/core/use-cases";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunction,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { loggerLink, unstable_httpBatchStreamLink } from "@trpc/client";
import { Effect, Option, Secret } from "effect";
import { useState } from "react";
import { SuperJSON } from "superjson";
import { defineLoaderEffect, ServerJsonResult } from "./.server";
import { ErrorPage } from "./components";
import { MessageToStreamTimingProvider } from "./hooks";
import "./root.css";
import { RootLoaderData, trpc } from "./utils";

// never revalidate (should be static data anyways)
export const shouldRevalidate: ShouldRevalidateFunction = () => false;

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        <link rel="apple-touch-icon" sizes="180x180" href="/__moneta-static__/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/__moneta-static__/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/__moneta-static__/favicon-16x16.png" />
        <link rel="manifest" href="/__moneta-static__/site.webmanifest" />
        <link rel="mask-icon" href="/__moneta-static__/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const loader = defineLoaderEffect(() =>
  Effect.gen(function* () {
    const urlBuilder = yield* DashboardUrlBuilder;
    const authCtx = yield* AuthenticationContext;
    const currentUserId = yield* authCtx.currentUserId().pipe(Effect.map(Option.getOrUndefined));
    const user = currentUserId !== undefined ? yield* FindUserById(currentUserId) : undefined;
    const { dsn, environment, release } = yield* GetFrontendInstrumentationConfiguration();

    const { userHost, landingHost, userAppHost } = yield* urlBuilder.getHosts();
    const { protocol } = yield* urlBuilder.getProtocol();

    return yield* ServerJsonResult.succeed({
      data: {
        baseHomeUrl: URLSegment.format(yield* urlBuilder.forLanding(yield* Pathname.root())),
        release,
        sentryDSN: Secret.value(dsn),
        sentryEnvironment: environment,
        sentryRelease: release,
        sentryUserId: currentUserId,
        sentryUsername: user?.username,
        user: user && {
          username: user.username,
          logoutUrl: URLSegment.format(yield* urlBuilder.forLanding(yield* Pathname.fromString("/auth/logout"))),
        },
        urls: {
          protocol,
          landingHost,
          userHost,
          userAppHost,
        },
      } satisfies RootLoaderData,
    });
  }),
);

const titleForStatus: Partial<Record<number, string>> = {
  [400]: "Bad request.",
  [401]: "Unauthorized.",
  [403]: "Forbidden.",
  [404]: "Not found.",
  [405]: "Method not allowed.",
  [406]: "Not acceptable.",

  [500]: "Something went wrong.",
  [501]: "Not implemented.",
  [502]: "Bad gateway.",
  [503]: "Service unavailable.",
  [504]: "Gateway timeout.",
};
const defaultTitle = "Something went wrong.";

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return <ErrorPage status={error.status} title={titleForStatus[error.status] ?? defaultTitle} />;
  }

  return <ErrorPage status={500} title={defaultTitle} />;
};

function App() {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        loggerLink(),
        unstable_httpBatchStreamLink({
          url: "/trpc",
          transformer: SuperJSON,
          headers: {
            "x-moneta-proxy-target": "remix",
          },
        }),
      ],
    }),
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <MessageToStreamTimingProvider>
          <Outlet />
        </MessageToStreamTimingProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default withSentry(App);
